import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";

class ErrorBoundary extends React.Component {
    render() {
        return (
            <div className={this.props.wrapper && "gray-bg justify-content-center vh-100"}>
                <div className="middle-box text-center animated fadeIn">
                    <h1>
                        <FontAwesomeIcon icon={faExclamation} />
                    </h1>
                    <h3 className="font-bold">Something went wrong</h3>

                    <div className="error-desc">
                        An unexpected error occurred.
                        <br />
                        The developers have been notified.
                        <br />
                        Our apologizes for the inconvenience.
                    </div>
                    <div className="mt-2">
                        {this.props.wrapper && (
                            <a href="/">
                                <button className="btn btn-primary">Go back to Home Page</button>
                            </a>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

ErrorBoundary.propTypes = {
    wrapper: PropTypes.bool,
    env: PropTypes.string,
    children: PropTypes.any,
};

export default ErrorBoundary;

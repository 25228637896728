import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

class SubMenuItem extends React.Component {
    renderLink() {
        let disabled = this.props.active === undefined ? false : !this.props.active;
        if (disabled) {
            return <a style={{ cursor: "not-allowed" }}>{this.props.name}</a>;
        } else {
            return (
                <Link to={this.props.link} onClick={this.props.handleClick}>
                    {this.renderIcon()}
                    {this.props.name}
                </Link>
            );
        }
    }

    renderIcon() {
        if (this.props.icon) {
            return (
                <React.Fragment>
                    <FontAwesomeIcon icon={this.props.icon} />
                    &nbsp;&nbsp;
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    render() {
        let active;
        if (this.props.matchingLinks) {
            active = this.props.matchingLinks.filter((link) => this.props.pathName.startsWith(link)).length > 0;
        } else {
            active = this.props.pathName === this.props.link;
        }

        return <li className={active ? "active" : ""}>{this.renderLink()}</li>;
    }
}

SubMenuItem.propTypes = {
    pathName: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    matchingLinks: PropTypes.arrayOf(PropTypes.string),
    active: PropTypes.bool,
    name: PropTypes.string,
    icon: PropTypes.object,
    handleClick: PropTypes.func,
};

export default SubMenuItem;

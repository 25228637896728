import { connect } from "react-redux";
import { ishkaAcpLogin, ishkaInsightsLogin, ishkaPlusLogin } from "../auth/actions/authActionCreators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import React, { useState } from "react";

function IshkaLinksButton(props) {
    const [showLinks, setShowLinks] = useState(false);

    const ishkaPlusLogin = () => {
        setShowLinks(false);
        props.ishkaPlusLogin().then((response) => {
            redirectExternal(response);
        });
    };

    const ishkaInsightsLogin = () => {
        setShowLinks(false);
        props.ishkaInsightsLogin().then((response) => {
            redirectExternal(response);
        });
    };

    const ishkaACPLogin = () => {
        setShowLinks(false);
        props.ishkaAcpLogin().then((response) => {
            redirectExternal(response);
        });
    };

    const redirectExternal = (response) => {
        if (response && response.value && response.value.data && response.value.data.url) {
            window.open(response.value.data.url, "_blank");
        }
    };

    return (
        <div className="dropdown">
            <button
                className="btn btn-xl btn-primary btn-rounded btn-icon other-ishka-websites-button"
                onMouseOver={() => setShowLinks(true)}
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-expanded={showLinks}
            >
                <FontAwesomeIcon icon={faChartLine} size="2x" />
            </button>
            <div
                className={`dropdown-menu dropdown-menu-right other-ishka-websites-dropdown${showLinks ? " show" : ""}`}
                onMouseLeave={() => setShowLinks(false)}
                aria-labelledby="dropdownMenuButton"
            >
                <a className="dropdown-item" onClick={() => ishkaACPLogin()}>
                    Airline Credit Profiles
                </a>
                <a className="dropdown-item" onClick={() => ishkaInsightsLogin()}>
                    Insights
                </a>
                <a className="dropdown-item" onClick={() => ishkaPlusLogin()}>
                    Ishka+
                </a>
            </div>
        </div>
    );
}

export default connect(null, { ishkaPlusLogin, ishkaInsightsLogin, ishkaAcpLogin })(IshkaLinksButton);

import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import ErrorBoundary from "../ErrorBoundary";
import IshkaLinksButton from "./IshkaLinksButton";

class PageWrapper extends React.Component {
    renderTitle() {
        if (this.props.title) {
            return (
                <div className="row wrapper page-heading align-items-center">
                    <div className="col-lg">
                        <div className="row">
                            <div className="col">
                                <h1 className="font-bold">{this.props.title}</h1>
                                {this.props.description && <h2>{this.props.description}</h2>}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-auto">
                        <div className="title-action">{this.props.action}</div>
                    </div>
                    <div className="col-lg-auto">
                        <IshkaLinksButton />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderTitle()}
                <div className="wrapper wrapper-content mx-auto animated fadeIn">
                    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>{this.props.children}</Sentry.ErrorBoundary>
                </div>
            </React.Fragment>
        );
    }
}

PageWrapper.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    action: PropTypes.object,
    children: PropTypes.any,
};

export default PageWrapper;

import React from "react";

const IshkaLogo = () => (
    <li className="nav-header">
        <div className="profile-element">
            <img src="/logo.png" alt="Ishka" title="Ishka" style={{ width: "100%" }} />
        </div>
        <div className="logo-element">Ishka</div>
    </li>
);

export default IshkaLogo;

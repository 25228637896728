import {
    AIRCRAFT_CONFIG_RESET,
    AIRCRAFT_CONFIG_RETRIEVE_ASSET_RISKS_FULFILLED,
    AIRCRAFT_CONFIG_RETRIEVE_CREDIT_RISKS_FULFILLED,
    AIRCRAFT_CONFIG_RETRIEVE_ENGINE_MODELS_FULFILLED,
    AIRCRAFT_CONFIG_RETRIEVE_MODIFIERS_FULFILLED,
    AIRCRAFT_CONFIG_RETRIEVE_MTOW_FULFILLED,
    AIRCRAFT_CONFIG_RETRIEVE_MTOW_PENDING,
    AIRCRAFT_CONFIG_RETRIEVE_VARIANTS_FULFILLED,
    AIRCRAFT_CONFIG_RETRIEVE_VINTAGES_FULFILLED,
} from "../constants/aircraftConfigurationConstants";
import { formatNumber } from "../../ishkaUtilities";

const defaultConfiguration = {
    aircraftVariants: [],
    engineModels: [],
    modifiers: [],
    vintages: [],
    creditRisks: [],
    assetRisks: [],
    mtow: {},
};

export const aircraftConfigurationReducer = (state = defaultConfiguration, action) => {
    switch (action.type) {
        case AIRCRAFT_CONFIG_RETRIEVE_VARIANTS_FULFILLED:
            return {
                ...state,
                aircraftVariants: action.payload.data.map((av) => {
                    return { label: av.name, value: av.id };
                }),
            };
        case AIRCRAFT_CONFIG_RETRIEVE_ENGINE_MODELS_FULFILLED:
            return {
                ...state,
                engineModels: action.payload.data.map((em) => {
                    return { label: em.code, value: em.id };
                }),
            };
        case AIRCRAFT_CONFIG_RETRIEVE_MODIFIERS_FULFILLED:
            return {
                ...state,
                modifiers: action.payload.data.map((mod) => {
                    return { label: mod.name, value: mod.id };
                }),
            };
        case AIRCRAFT_CONFIG_RETRIEVE_VINTAGES_FULFILLED:
            return {
                ...state,
                vintages: action.payload.data.map((vintage) => {
                    return { label: vintage, value: vintage };
                }),
            };
        case AIRCRAFT_CONFIG_RETRIEVE_MTOW_PENDING:
            return {
                ...state,
                mtow: {},
            };
        case AIRCRAFT_CONFIG_RETRIEVE_MTOW_FULFILLED:
            return {
                ...state,
                mtow: action.payload.data,
            };
        case AIRCRAFT_CONFIG_RESET:
            return {
                ...state,
                engineModels: [],
                modifiers: [],
                vintages: [],
            };
        case AIRCRAFT_CONFIG_RETRIEVE_CREDIT_RISKS_FULFILLED:
            return {
                ...state,
                creditRisks: [
                    ...action.payload.data
                        .filter((risk) => risk.rating !== "BANKRUPT")
                        .map((risk) => {
                            return {
                                value: risk.rating,
                                label: `${risk.label} ${formatNumber(risk.value, "0.00%")}`,
                            };
                        }),
                    { value: "CUSTOM", label: "Custom" },
                ],
            };
        case AIRCRAFT_CONFIG_RETRIEVE_ASSET_RISKS_FULFILLED:
            return {
                ...state,
                assetRisks: [
                    ...action.payload.data.map((risk) => {
                        return {
                            value: risk.rating,
                            label: `${risk.label} ${formatNumber(risk.value, "0.00%")}`,
                        };
                    }),
                    { value: "CUSTOM", label: "Custom" },
                ],
            };
        case "@@redux-form/CHANGE": {
            if (action.meta.form === "new-portfolio-entry" && action.meta.field === "aircraftVariant") {
                return {
                    ...state,
                    engineModels: [],
                    modifiers: [],
                    vintages: [],
                };
            } else {
                return state;
            }
        }

        default:
            return state;
    }
};

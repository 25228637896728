import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";

const TopNavBar = () => (
    <div className="row">
        <nav className="navbar navbar-static-top" role="navigation">
            <div className="navbar-header">
                <a className="navbar-minimalize minimalize-styl-2 btn btn-default " href="#">
                    <FontAwesomeIcon icon={faBars} />
                </a>
            </div>
        </nav>
    </div>
);

export default TopNavBar;

import { applyMiddleware, compose, createStore } from "redux";

import thunk from "redux-thunk";
import promise from "redux-promise-middleware";

const configureStore = (railsProps, reducers) => createIshkaStore(reducers);

// This middleware allows us to call .then() after dispatching an action.
// This is useful for cases where we have to wait for an action to finish
// dispatching & before executing some code (e.g. focusing a DOM control).
// Taken from https://stackoverflow.com/a/41539956/3007149.
const thenMiddleware = () => (next) => (action) => {
    return new Promise((resolve, reject) => {
        try {
            resolve(next(action));
        } catch (e) {
            reject(e);
        }
    });
};

function createIshkaStore(reducers) {
    if (process.env.NODE_ENV === "development") {
        const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

        return createStore(
            reducers,
            /* preloadedState, */ composeEnhancers(applyMiddleware(promise, thunk, thenMiddleware))
        );
    } else {
        return createStore(reducers, {}, applyMiddleware(promise, thunk, thenMiddleware));
    }
}

export const createFormData = (name, data) => {
    let formData = new FormData();
    formData.append(name, data);
    return formData;
};

export const selectIshkaStyles = (height = "calc(1.5em + 0.75rem + 4px)") => {
    return {
        singleValue: (styles, { isFocused }) => {
            return {
                ...styles,
                color: isFocused ? "black" : "#676a6c",
            };
        },
        indicatorsContainer: (styles) => {
            return {
                ...styles,
                height: height === "unset" ? height : "100%",
            };
        },
        indicatorSeparator: (styles) => {
            return {
                ...styles,
                backgroundColor: "#e5e6e7",
                margin: "unset",
            };
        },
        container: (styles) => {
            return {
                ...styles,
                height: height,
                minHeight: "unset",
            };
        },
        control: (styles, { isDisabled }) => {
            return {
                ...styles,
                height: "100%",
                minHeight: "unset",
                borderRadius: "unset",
                borderColor: "#e5e6e7",
                backgroundColor: isDisabled ? "#e9ecef" : "white",
            };
        },
        menu: (styles) => {
            return {
                ...styles,
                zIndex: 2501,
            };
        },
        option: (styles, { isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? "#e9ecef"
                    : isSelected
                    ? "#007EA6"
                    : isFocused
                    ? "rgba(0, 126, 166, 0.8)"
                    : null,
                color: isDisabled ? "grey" : isSelected ? "white" : isFocused ? "white" : null,
            };
        },
    };
};

export default configureStore;

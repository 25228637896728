import {
    USER_FORGOT_PASSWORD,
    USER_SIGN_IN,
    USER_SIGN_IN_ISHKA_ACP,
    USER_SIGN_IN_ISHKA_INSIGHTS,
    USER_SIGN_IN_ISHKA_PLUS,
    USER_SIGN_OUT,
} from "../constants/authConstants";
import instance from "../../axiosconfig";

export const signInUser = (email, password) => ({
    type: USER_SIGN_IN,
    payload: instance.post("/users/sign_in", {
        user: {
            email: email,
            password: password,
            remember_me: true,
        },
    }),
});

export const signOutUser = () => ({
    type: USER_SIGN_OUT,
    payload: instance.delete("/users/sign_out"),
});

export const forgotPassword = (email) => ({
    type: USER_FORGOT_PASSWORD,
    payload: instance.post("/users/password", {
        user: {
            email: email,
        },
    }),
});

export const ishkaPlusLogin = () => ({
    type: USER_SIGN_IN_ISHKA_PLUS,
    payload: instance.post("/users/ishka_plus_login"),
});

export const ishkaInsightsLogin = () => ({
    type: USER_SIGN_IN_ISHKA_INSIGHTS,
    payload: instance.post("/users/ishka_insights_login"),
});

export const ishkaAcpLogin = () => ({
    type: USER_SIGN_IN_ISHKA_ACP,
    payload: instance.post("/users/ishka_acp_login"),
});

import PageWrapper from "./PageWrapper";
import React from "react";
import Loading from "../inspinia/Loading";

const LoadingPage = (props) => (
    <PageWrapper title="&nbsp;" {...props}>
        <div className="middle-box text-center">
            <Loading />
        </div>
    </PageWrapper>
);

export default LoadingPage;

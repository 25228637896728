import { USER_SIGN_IN_FULFILLED, USER_SIGN_OUT_FULFILLED } from "../constants/authConstants";
import * as Sentry from "@sentry/react";
import amplitude from "amplitude-js";

export const INIT_STORE = "INIT_STORE";

const AMPLITUDE_PRODUCTION_API_KEY = "7d7ca7aea0275be3b06508a5b1ff5df7";
const AMPLITUDE_STAGING_API_KEY = "369d09963e6772ee8c757a82d6c2ca5b";
const AMPLITUDE_DEVELOPMENT_API_KEY = "054342004a0d32e7c9eefa142ad292f7";

const amplitude_key = (env) => {
    if (env === "production") {
        return AMPLITUDE_PRODUCTION_API_KEY;
    } else if (env === "staging") {
        return AMPLITUDE_STAGING_API_KEY;
    } else {
        return AMPLITUDE_DEVELOPMENT_API_KEY;
    }
};

export const userReducer = (state = { user: {}, env: "production" }, action) => {
    switch (action.type) {
        case INIT_STORE: {
            initAmplitude(action.payload.data);
            configureSentry(action.payload.data.user);
            return {
                ...state,
                ...(action.payload.data.user && { user: action.payload.data.user }),
                ...(action.payload.data.env && { env: action.payload.data.env }),
            };
        }

        case USER_SIGN_IN_FULFILLED:
            configureSentry(action.payload.data);
            configureAmplitude(action.payload.data);
            return {
                ...state,
                user: action.payload.data,
            };
        case USER_SIGN_OUT_FULFILLED:
            configureSentry({ email: null, roles: null });
            configureAmplitude({ email: null });
            return {
                ...state,
                user: {},
            };
        default:
            return state;
    }
};

function configureSentry(user) {
    if (user) {
        Sentry.configureScope((scope) => scope.setUser({ id: user.id, roles: user.roles }));
    }
}

function initAmplitude(data) {
    if (data.env) {
        amplitude.getInstance().init(amplitude_key(data.env), null, {
            sameSiteCookie: "lax",
            cookieForceUpgrade: true,
            secureCookie: true,
        });
        amplitude.getInstance().setVersionName(data.release_version || "unknown");
    }
    configureAmplitude(data.user);
}

function configureAmplitude(user) {
    if (user) {
        amplitude.getInstance().setUserId(user.email);
    }
}

export const USER_SIGN_IN = "USER_SIGN_IN";
export const USER_SIGN_IN_PENDING = "USER_SIGN_IN_PENDING";
export const USER_SIGN_IN_FULFILLED = "USER_SIGN_IN_FULFILLED";
export const USER_SIGN_IN_REJECTED = "USER_SIGN_IN_REJECTED";
export const USER_SIGN_OUT = "USER_SIGN_OUT";
export const USER_SIGN_OUT_PENDING = "USER_SIGN_OUT_PENDING";
export const USER_SIGN_OUT_FULFILLED = "USER_SIGN_OUT_FULFILLED";
export const USER_FORGOT_PASSWORD = "USER_FORGOT_PASSWORD";
export const USER_FORGOT_PASSWORD_PENDING = "USER_FORGOT_PASSWORD_PENDING";
export const USER_FORGOT_PASSWORD_FULFILLED = "USER_FORGOT_PASSWORD_FULFILLED";
export const USER_SIGN_IN_ISHKA_PLUS = "USER_SIGN_IN_ISHKA_PLUS";
export const USER_SIGN_IN_ISHKA_INSIGHTS = "USER_SIGN_IN_ISHKA_INSIGHTS";
export const USER_SIGN_IN_ISHKA_ACP = "USER_SIGN_IN_ISHKA_ACP";

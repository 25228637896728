export function createSearchReducer(entities) {
    function createDefaultConfiguration() {
        let state = {};
        for (const entity of entities) {
            state = {
                ...state,
                [entity.name]: {
                    ...defaultEntityConfiguration,
                    filter: entity.filter || {},
                },
            };
        }
        return state;
    }

    const defaultEntityConfiguration = {
        data: [],
        total: 0,
        page: 1,
        pages: 0,
        page_size: 10,
        series: [],
        filter: {},
        sort: { field: "id", type: "ASC" },
        fetching: false,
    };

    return function search(state = createDefaultConfiguration(), action) {
        const name = action.type.split("/")[0];
        const entity = name.toLowerCase();

        switch (action.type) {
            case `${name}/SEARCH_RESET`: {
                return {
                    ...state,
                    [entity]: defaultEntityConfiguration,
                };
            }
            case `${name}/SEARCH_PENDING`:
                return {
                    ...state,
                    [entity]: {
                        ...state[entity],
                        fetching: true,
                    },
                };
            case `${name}/SEARCH_FULFILLED`:
                return {
                    ...state,
                    [entity]: {
                        ...state[entity],
                        ...action.payload.data,
                        fetching: false,
                    },
                };

            case `${name}/SEARCH_REJECTED`:
                return {
                    ...state,
                    [entity]: {
                        ...state[entity],
                        fetching: false,
                    },
                };
            case `${name}/SEARCH_CHANGE_PAGE`:
                return {
                    ...state,
                    [entity]: {
                        ...state[entity],
                        page: action.payload,
                    },
                };
            case `${name}/SEARCH_CHANGE_FILTER`:
                return {
                    ...state,
                    [entity]: {
                        ...state[entity],
                        filter: action.payload,
                        page: 1,
                    },
                };
            case `${name}/SEARCH_CHANGE_SORT`:
                return {
                    ...state,
                    [entity]: {
                        ...state[entity],
                        sort: action.payload,
                    },
                };
            case `${name}/DESTROY_FULFILLED`:
                if (state[entity].data.length === 1 && state[entity].page !== 1) {
                    return {
                        ...state,
                        [entity]: {
                            ...state[entity],
                            page: state[entity].page - 1,
                            pages: state[entity].pages - 1,
                        },
                    };
                } else {
                    return state;
                }
            default:
                return state;
        }
    };
}

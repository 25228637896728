import React from "react";
import PropTypes from "prop-types";

const NotFound = (props) => (
    <div className="middle-box text-center animated fadeIn">
        <h1>404</h1>
        <h3 className="font-bold">{props.entity} Not Found</h3>

        <div className="error-desc">
            Sorry, but the {props.entity.toLowerCase()} you are looking for has not been found.
        </div>
    </div>
);

NotFound.defaultProps = {
    entity: "Page",
};

NotFound.propTypes = {
    entity: PropTypes.string,
};

export default NotFound;

import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";

class MenuItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            className: "",
        };
        this.onClickEvent = this.onClickEvent.bind(this);
    }

    renderArrow() {
        if (this.props.children && this.props.link !== "/valuations") {
            if (this.isActive()) {
                return <FontAwesomeIcon icon={faAngleDown} fixedWidth />;
            } else {
                return <FontAwesomeIcon icon={faAngleLeft} fixedWidth />;
            }
        } else {
            return null;
        }
    }

    isActive() {
        if (this.props.matchingLinks) {
            for (let i = 0; i < this.props.matchingLinks.length; i++) {
                if (this.props.pathName.startsWith(this.props.matchingLinks[i])) {
                    return true;
                }
            }
            return false;
        } else if (this.props.children) {
            return this.props.pathName.startsWith(this.props.link);
        } else {
            return this.props.pathName === this.props.link;
        }
    }

    onClickEvent() {
        if (this.props.onClickEvent) {
            this.props.onClickEvent();
        }
    }

    render() {
        if (this.props.link) {
            return (
                <li onClick={this.onClickEvent} className={this.isActive() ? "active" : ""}>
                    <Link to={this.props.link}>
                        <FontAwesomeIcon icon={this.props.icon} className="mr-2" />
                        <span className="nav-label">{this.props.label}</span>
                        <div className="d-none d-sm-block float-right">{this.renderArrow()}</div>
                    </Link>
                    {this.props.children}
                </li>
            );
        } else if (this.props.url) {
            return (
                <li onClick={this.onClickEvent} className={this.isActive() ? "active" : ""}>
                    <a href={this.props.url}>
                        <FontAwesomeIcon icon={this.props.icon} className="mr-2" />
                        <span className="nav-label">{this.props.label}</span>
                        <div className="d-none d-sm-block float-right">{this.renderArrow()}</div>
                    </a>
                    {this.props.children}
                </li>
            );
        } else {
            return null;
        }
    }
}

export default MenuItem;

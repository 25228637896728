import amplitude from "amplitude-js";
import { format, parse, parseISO } from "date-fns";
import numeral from "numeral";

export const KG_TO_LB = 2.204623;

export const depreciateModifier = (intialCost, age) => {
    if (age > 100) {
        return intialCost;
    }
    let costResult = intialCost;
    for (let i = 1; i <= age; i++) {
        if (i < 16) {
            costResult = costResult * 0.95;
        } else {
            costResult = costResult * 0.99;
        }
    }
    return costResult;
};

export const round = (value, decimals) => {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

export const trunc = (value, decimals) => {
    return Number(Math.trunc(value + "e" + decimals) + "e-" + decimals);
};

export const uniqId = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
};

export const sendToAmplitude = (eventName, eventProperties) => {
    if (!window.location.pathname.startsWith("/admin")) {
        try {
            amplitude.getInstance().logEvent(eventName, eventProperties);
        } catch (e) {
            console.warn("Event not sent to amplitude", e);
        }
    }
};

export const formatShortDate = (value) => {
    return value ? format(value, "yyyy-MM-dd") : "";
};

export const parseAndFormatShortDate = (value) => {
    if (value) {
        const parsed = parse(value, "yyyy-MM-dd", new Date());
        return format(parsed, "dd-MM-yyyy");
    } else {
        return "";
    }
};

export const formatRailsDate = (value, formatPattern) => {
    if (!value) return "";
    const parsed = parseISO(value);
    return format(parsed, formatPattern);
};

export const generateVintages = () => {
    const list = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 1970; i--) {
        list.push(i);
    }
    return list;
};

export const generateVintageOptions = (firstYear = 1970) => {
    const list = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= firstYear; i--) {
        list.push({ value: i, label: i });
    }
    return list;
};

export const generateYearOptions = (from, to) => {
    const list = [];
    for (let i = to; i >= from; i--) {
        list.push({ value: i, label: i });
    }
    return list;
};

export const hasRole = (user, role) => {
    return user && user.roles ? user.roles.includes(role) : false;
};

export const formatNumber = (number, format) => {
    if (!parseFloat(number)) {
        return number;
    }
    if (number) {
        return numeral(number).format(format);
    } else {
        return "";
    }
};

export const formatAmount = (number) => {
    return numeral(number).format("$ 0,0.00");
};

Array.range = (start, end) => Array.from({ length: end - start }, (v, k) => k + start);

function pad(number) {
    if (number < 10) {
        return "0" + number;
    }
    return number;
}

export const dateString = (date) => {
    return date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate());
};

export const subtractYear = (date, years) => {
    date.setFullYear(date.getFullYear() - years);
    return date;
};

import React from "react";

class Loading extends React.Component {
    render() {
        return (
            <div className="sk-spinner sk-spinner-wave mt-2">
                <div className="sk-rect1" />
                <div className="sk-rect2" />
                <div className="sk-rect3" />
                <div className="sk-rect4" />
                <div className="sk-rect5" />
            </div>
        );
    }
}

export default Loading;

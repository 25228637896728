import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const YEAR = new Date().getFullYear();

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div>
                    <strong>&copy; {YEAR} Ishka Ltd</strong> Registered in England and Wales under registration number -
                    09973090
                    {this.envBanner()}
                </div>
            </div>
        );
    }

    envBanner() {
        if (!this.props.env || this.props.env === "production") {
            return null;
        } else {
            return (
                <div className="text-warning float-right">
                    <strong>{this.props.env}</strong> environment
                </div>
            );
        }
    }
}

Footer.propTypes = {
    env: PropTypes.string,
};

export default connect((state) => {
    return { env: state.userReducer.env };
}, null)(Footer);

export const AIRCRAFT_CONFIG_RETRIEVE_VARIANTS = "AIRCRAFT_CONFIG/RETRIEVE_VARIANTS";
export const AIRCRAFT_CONFIG_RETRIEVE_VARIANTS_FULFILLED = "AIRCRAFT_CONFIG/RETRIEVE_VARIANTS_FULFILLED";

export const AIRCRAFT_CONFIG_RETRIEVE_ENGINE_MODELS = "AIRCRAFT_CONFIG/RETRIEVE_ENGINE_MODELS";
export const AIRCRAFT_CONFIG_RETRIEVE_ENGINE_MODELS_FULFILLED = "AIRCRAFT_CONFIG/RETRIEVE_ENGINE_MODELS_FULFILLED";

export const AIRCRAFT_CONFIG_RETRIEVE_AIRCRAFT_BASELINE = "AIRCRAFT_CONFIG/RETRIEVE_AIRCRAFT_BASELINE";

export const AIRCRAFT_CONFIG_RETRIEVE_MODIFIERS = "AIRCRAFT_CONFIG/RETRIEVE_MODIFIERS";
export const AIRCRAFT_CONFIG_RETRIEVE_MODIFIERS_FULFILLED = "AIRCRAFT_CONFIG/RETRIEVE_MODIFIERS_FULFILLED";

export const AIRCRAFT_CONFIG_RETRIEVE_VINTAGES = "AIRCRAFT_CONFIG/RETRIEVE_VINTAGES";
export const AIRCRAFT_CONFIG_RETRIEVE_VINTAGES_FULFILLED = "AIRCRAFT_CONFIG/RETRIEVE_VINTAGES_FULFILLED";

export const AIRCRAFT_CONFIG_RETRIEVE_DEFAULT_BASELINE = "AIRCRAFT_CONFIG/RETRIEVE_DEFAULT_BASELINE";

export const AIRCRAFT_CONFIG_RETRIEVE_MTOW_PENDING = "AIRCRAFT_CONFIG/RETRIEVE_MTOW_PENDING";
export const AIRCRAFT_CONFIG_RETRIEVE_MTOW = "AIRCRAFT_CONFIG/RETRIEVE_MTOW";
export const AIRCRAFT_CONFIG_RETRIEVE_MTOW_FULFILLED = "AIRCRAFT_CONFIG/RETRIEVE_MTOW_FULFILLED";

export const AIRCRAFT_CONFIG_RETRIEVE_CREDIT_RISKS = "AIRCRAFT_CONFIG/RETRIEVE_CREDIT_RISKS";
export const AIRCRAFT_CONFIG_RETRIEVE_CREDIT_RISKS_FULFILLED = "AIRCRAFT_CONFIG/RETRIEVE_CREDIT_RISKS_FULFILLED";

export const AIRCRAFT_CONFIG_RETRIEVE_ASSET_RISKS = "AIRCRAFT_CONFIG/RETRIEVE_ASSET_RISKS";
export const AIRCRAFT_CONFIG_RETRIEVE_ASSET_RISKS_FULFILLED = "AIRCRAFT_CONFIG/RETRIEVE_ASSET_RISKS_FULFILLED";

export const AIRCRAFT_CONFIG_RESET = "AIRCRAFT_CONFIG/RESET";
